<template>
  <div class="body">
		<div class="table-list">
			<Table :loading="loading" :columns="columns" :data="data"></Table>
		</div>
			<div class="ser-page">
				<Page class-name="page-r" :total="SearchForm.total" :current="SearchForm.page" :page-size="SearchForm.limit" :page-size-opts="[15,30,50]" show-total show-sizer @on-change="set_current" @on-page-size-change="set_per"></Page>
			</div>

  </div>
</template>

<script>
    export default {
        name: "SmsLog",
		data() {
			return {
				loading: false,
				SearchForm: {
					page: 1,
					limit: 10,
					total: 0
				},
				columns: [
					{title: 'ID', key: 'id', minWidth: 100},
					{title: '套餐名称', key: 'name', minWidth: 150},
					{title: '到期时间', key: 'expiration_time', minWidth: 150},
					{title: '剩余条数', key: 'total_number', minWidth: 200,'align': 'center'},
					{title: '使用条数', key: 'use_number', minWidth: 100,'align': 'center'},
					{title: '创建时间', key: 'create_time', minWidth: 150}
				],
				data:[]
			}
		},
		created() {
			this.init()
		},
		methods: {
			init() {
				var _this = this;
				this.loading = true;
				this.requestApi('/adm/get_record_list',{data:this.SearchForm}).then(
					(res) => {
						if (res.code == 1){
							_this.data = res.data.data;
							_this.SearchForm.total = res.data.total;
							_this.loading = false;
						}
					}
				)
			},
			//切换页数
			set_current(page) {
				this.SearchForm.page = page
				this.init()
			},
			//切换条数
			set_per(limit) {
				this.SearchForm.limit = limit
				this.init()
			}
		}
    }
</script>

<style scoped>
	.body {
		background-color: #fff;
	}
	.page-r {
		margin-top: 10px;
		text-align: right;
	}
	.ser-page{
		padding-bottom: 10px;
	}
</style>
